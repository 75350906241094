import React from 'react'
import PropTypes from 'prop-types'
import {graphql, Link} from 'gatsby'

import '../../pricing-pages/styles.scss'
import Layout from '../../../components/Layout'
import Content, {HTMLContent} from '../../../components/Content'
import SharedJumbotron from '../../../components/SharedJumbotron'
import DownloadNow from '../../../components/DownloadNow'

type HKStockOptionsPageProps = {
  content: Node.isRequired,
  contentComponent: any,
  downloadNow: {
    mainText: string,
    subText: string,
    image: any,
  },
  headerImage: any,
}

export const HKStockOptionsPageTemplate = (props: HKStockOptionsPageProps) => {
  const PostContent = props.contentComponent || Content
  const {content, headerImage, downloadNow} = props

  return (
    <div className="pricing-pages">
      <SharedJumbotron headerImage={headerImage} />

      <section className="blog-post-container" style={{paddingBottom: '250px'}}>
        <div className="container content">
          <div className="row justify-content-center">
            <div className="col col-10 blog-container">
              <h3>
                HONG KONG STOCK OPTIONS
              </h3>
              {/* <PostContent content={content} /> */}
              <h5>1. Commission</h5>
              <h5>Options</h5>
              <div className="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Item</th>
                      <th scope="col">Rate</th>
                      <th scope="col">Charged by</th>
                    </tr>
                  </thead>
                  <tr>
                    <td>Commission</td>
                    <td>Total transaction amount × 0.2%, with a minimum of HKD 3 per order</td>
                    <td>Snowball X</td>
                  </tr>
                  <tr>
                    <td>Platform Usage Fee</td>
                    <td>HKD 15 per order (waived if transaction value is ≥ HKD 60,000)</td>
                    <td>Snowball X</td>
                  </tr>
                  <tr>
                    <td>System Usage Fee</td>
                    <td></td>
                    <td>Hong Kong Stock Exchange</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Category 1: HKD 3 per contract</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Category 2: HKD 1 per contract</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Category 3: HKD 0.5 per contract</td>
                    <td></td>
                  </tr>
                </table>
              </div>
              <p>
                Note: For specific option categories, please visit the Hong Kong Stock Exchange website.
                <br />
              </p>
              <h5>Index Options</h5>
              <div className="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Pricing Currency</th>
                      <th scope="col">Commission Per Contract</th>
                      <th scope="col">Minimum Charge Per Order</th>
                      <th scope="col">Exchange and Clearing Fees</th>
                    </tr>
                  </thead>
                  <tr>
                    <td>HKD (Contract)</td>
                    <td>HKD 8.5</td>
                    <td>HKD 8.5</td>
                    <td>See detailed breakdown below</td>
                  </tr>
                </table>
              </div>
              <br />
              <div className="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Code</th>
                      <th scope="col">Exchange Fee</th>
                      <th scope="col">SFC Levy</th>
                      <th scope="col">Clearing Fee</th>
                    </tr>
                  </thead>
                  <tr>
                    <td>HSI</td>
                    <td>HKD 10</td>
                    <td>HKD 0.54</td>
                    <td>HKD 10</td>
                  </tr>
                  <tr>
                    <td>MHI</td>
                    <td>HKD 2</td>
                    <td>HKD 0.1</td>
                    <td>HKD 2</td>
                  </tr>
                  <tr>
                    <td>HHI</td>
                    <td>HKD 3.5</td>
                    <td>HKD 0.54</td>
                    <td>HKD 3.5</td>
                  </tr>
                  <tr>
                    <td>HTI</td>
                    <td>HKD 5</td>
                    <td>HKD 0.54</td>
                    <td>HKD 2.5</td>
                  </tr>
                  <tr>
                    <td>MCH</td>
                    <td>HKD 1</td>
                    <td>HKD 0.54</td>
                    <td>HKD 1</td>
                  </tr>
                </table>
              </div>
              <p>
                The above fees are subject to change. Please refer to actual charges.
                <br />
                <br />
              </p>

              <h5>2. Exercise and Assignment Fees for Hong Kong Stock Options</h5>
              <div className="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Item</th>
                      <th scope="col">Rate</th>
                      <th scope="col">Charged by</th>
                    </tr>
                  </thead>
                  <tr>
                    <td>Exercise Fee</td>
                    <td>HKD 2 per contract</td>
                    <td>Hong Kong Stock Exchange</td>
                  </tr>
                  <tr>
                    <td>Trading Commission</td>
                    <td>Follows the commission package for your Hong Kong stock account</td>
                    <td>Snowball X</td>
                  </tr>
                  <tr>
                    <td>Transaction Fee</td>
                    <td>0.005% × total transaction value + HKD 0.5</td>
                    <td>Hong Kong Stock Exchange</td>
                  </tr>
                  <tr>
                    <td>Clearing Fee</td>
                    <td>0.002% × total transaction value (minimum HKD 2, maximum HKD 100)</td>
                    <td>Hong Kong Clearing Limited</td>
                  </tr>
                  <tr>
                    <td>Transaction Levy</td>
                    <td>0.0027% × total transaction value</td>
                    <td>Securities and Futures Commission (SFC)</td>
                  </tr>
                  <tr>
                    <td>Stamp Duty</td>
                    <td>0.1% × total transaction value (rounded up to a minimum of HKD 1)</td>
                    <td>Hong Kong Government</td>
                  </tr>
                  <tr>
                    <td>Platform Fee</td>
                    <td>HKD 15 per order (waived if transaction value is ≥ HKD 60,000)</td>
                    <td>Snowball X</td>
                  </tr>
                </table>
              </div>
              <p>
                No exercise fee is charged when options are assigned.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const HKStockOptionsPage = ({data}) => {
  const {markdownRemark: post} = data

  return (
    <Layout footerLinks={post.frontmatter.footerLinks}>
      <HKStockOptionsPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        downloadNow={post.frontmatter.downloadNow}
        headerImage={post.frontmatter.headerImage}
      />
    </Layout>
  )
}

HKStockOptionsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default HKStockOptionsPage

export const pageQuery = graphql`
  query HKStockOptions($id: String!) {
    markdownRemark(id: {eq: $id}) {
      id
      html
      frontmatter {
        headerImage {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        downloadNow {
          mainText
          subText
          image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        footerLinks {
          firstRow {
            name
            href
          }
          secondRow {
            name
            href
          }
        }
      }
    }
  }
`
